/**
 * HONEY ESIM 메인 페이지 HERO SECTION
 */

import HeroSearchForm from 'components/HeroSearchForm/HeroSearchForm';
import imagePng from 'images/hero-right.webp';
import { FC } from 'react';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
export interface SectionHeroProps {
  className?: string;
}

const SectionHero: FC<SectionHeroProps> = ({ className = '' }) => {
  return (
    <div
      className={`nc-SectionHero flex flex-col-reverse lg:flex-col relative ${className}`}
      data-nc-id='SectionHero'
    >
      <div className='flex flex-col lg:flex-row items-center'>
        <div className='flex-shrink-0 lg:w-1/2 flex flex-col items-center lg:items-start space-y-8 sm:space-y-10 pb-14 lg:pb-64 xl:pr-14 lg:mr-8 xl:mr-0'>
          <h2 className='font-medium text-4xl md:text-5xl xl:text-6xl !leading-[114%] text-center lg:text-start'>
            유심없는 해외로밍에
            <div className='font-semibold'>저렴한 데이터까지,</div>
            <div className='font-bold md:text-6xl xl:text-7xl mt-2'>
              완전 꿀eSIM!
            </div>
          </h2>

          <span className='text-lg md:text-xl text-neutral-500 dark:text-neutral-400'>
            🇺🇸🇨🇦 미국 캐나다 교민분들을 위한 eSIM 서비스
            <div className='mt-1'>
              ✈️ 여행가실 때 저렴한 가격으로 데이터를 사용하세요
            </div>
            <div className='mt-1'>🗨️📧 1분 안으로 카톡·이메일 전송</div>
          </span>
          <ButtonPrimary className={'bg-neutral-100'} href='/products'>
            구매 가능한 eSIM 상품 보기
          </ButtonPrimary>
        </div>
        <div className='flex-grow hidden lg:block'>
          <img className='w-full' src={imagePng} alt='hero' />
        </div>
      </div>

      {/* 국가검색 */}
      <div className='hidden md:block z-10 mb-12 lg:mb-0 lg:-mt-40 w-full'>
        <HeroSearchForm />
      </div>
    </div>
  );
};

export default SectionHero;
