import Heading from 'components/Heading/Heading';
import { FC } from 'react';

export interface Statistic {
  id: string;
  heading: string;
  subHeading: string;
}

const FOUNDER_DEMO: Statistic[] = [
  {
    id: '1',
    heading: '28,600원',
    subHeading: '로밍도OO',
  },
  {
    id: '2',
    heading: '25,500원',
    subHeading: '유심O',
  },
];

export interface SectionStatisticProps {
  className?: string;
}

const SectionStatistic: FC<SectionStatisticProps> = ({ className = '' }) => {
  return (
    <div className={`nc-SectionStatistic relative ${className}`}>
      <Heading
        desc='다른 eSIM 판매업체 대비 최고 50% 저렴합니다'
        isCenter={true}
      >
        저렴한 가격
      </Heading>
      <div className='grid md:grid-cols-2 gap-6 lg:grid-cols-3 xl:gap-8'>
        {FOUNDER_DEMO.map((item) => (
          <div
            key={item.id}
            className='p-6 bg-neutral-50 dark:bg-neutral-800 rounded-2xl dark:border-neutral-800'
          >
            <span className='text-neutral-500'> 한국 매일 1GB (15일)</span>

            <h3 className='text-2xl  leading-none text-neutral-900 md:text-3xl dark:text-neutral-200'>
              {item.heading}
            </h3>
            <span className='block text-sm text-neutral-500 mt-3 sm:text-base dark:text-neutral-400 font-bold'>
              {item.subHeading}
            </span>
          </div>
        ))}
        <div className='p-6 bg-primary-100 dark:bg-neutral-800 rounded-2xl dark:border-neutral-800 border border-primary-400'>
          <span className='text-neutral-500'> 한국 매일 1GB (15일)</span>

          <h3 className='text-2xl font-semibold leading-none text-neutral-900 md:text-3xl dark:text-neutral-200'>
            {'18,960원'}
          </h3>
          <span className='block text-sm text-neutral-700 mt-3 sm:text-base dark:text-neutral-400 font-bold'>
            {'꿀이심 👍'}
          </span>
        </div>
      </div>
      <div className='flex items-end mt-10'>
        <div className='ml-auto text-xs text-neutral-500'>
          * 2024년 5월 기준
        </div>
      </div>
    </div>
  );
};

export default SectionStatistic;
